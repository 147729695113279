














































import {Vue, Component, Prop, Emit} from "vue-property-decorator";

@Component
export default class el_search extends Vue {
    @Prop() search?: Array<any>;
    @Emit('on-height-change')
    mounted() {
        let self: any = this;
        return self.$refs['el-search']?.offsetHeight;
    }
    created() {
        this.search?.forEach((j: any) => {
            if ((j.type === 'RadioGroup' || j.type === 'Select') && !j.onchange) j.onchange = () => {}
            if (typeof j.items === 'function') j.items().then((data: any) => j.items = data)
        });
    }
    @Emit('on-search')
    onSearch() {
        let params: Array<any> = [];
        let f: boolean = true;
        this.search?.forEach((s: any, index: number) => {
            if (s.value !== undefined && s.value !== '') {
                if (s.pickerType && s.pickerType?.indexOf('range') !== -1) {
                    if (s.value[0])
                        params.push({'startTime': s.value[0]});
                    if (s.value[1])
                        params.push({'endTime': s.value[1]});
                } else {
                    params.push({[s.key]: s.value})
                }
                if (Array.isArray(s.value)) {
                    let b: boolean = s.value.every((j: any) => (j === undefined) || (j === ''));
                    if (b && s.require) {
                        this.$Message.warning(`${s.label}不能为空`);
                        f = false;
                    }
                }
            } else if (s.require) {
                this.$Message.warning(`${s.label}不能为空`);
                f = false;
            }
        })
        return f? params: null;
    }
}

